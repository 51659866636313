import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import ReactPlayer from "react-player"

const StratagicHR = ({ data }) => {
  const images = {
    subHeader: convertToBgImage(getImage(data.subHeader)),
  }
  return (
    <Layout>
      <Seo title="Success stories | Redefining Travel" />
      <section>
        <div className="main">
          <BackgroundImage
            {...images.subHeader}
            className="sap-header sub-success"
          >
            <div className="container">
              <div className="sap-header-content">
                <div className="img">
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/sap/clients/teejay.png"
                    width={300}
                    alt="techpac"
                  />
                </div>
                <div className="success-header-title">Strategic HR</div>
                <div className="header-description">
                  Enabling businesses to manage and accelerate growth by
                  furthering their HR strategy while ensuring compliance across
                  sectors.
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="sub-success-content">
            <div className="row">
              <div className="col-md-6 sub-success-details">
                <p>
                  Teejay Lanka is the largest textile group and knit fabric
                  provider in South Asia. Specializing in weft knitting, it is
                  regarded as Sri Lanka’s only multinational mill. Its customer
                  portfolio encompasses global brands such as Calvin Klein and
                  Victoria’s Secret.
                </p>
                <p>
                  Tech Pacific successfully carried out a full implementation of
                  the SAP SuccessFactors solution for Teejay Lanka, including
                  the Employee Central, Time & Attendance and Performance
                  Management & Goal Management modules. The Digital
                  Transformation empowered employees and managers with direct
                  access to information and tools they wanted and company
                  leaders greater insight into their data, contributing to
                  increased team productivity, talent development and longer
                  employee tenure at all levels. The implementation took place
                  simultaneously across properties in Teejay’s factories in Sri
                  Lanka and India.
                </p>
                {/* <ReactPlayer
                  controls={true}
                  url="https://www.youtube.com/watch?v=dDS3hAFQuS8"
                /> */}
              </div>
              <div className="col-md-6 sub-success-points">
                <div className="sub-success-items">
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      Client
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">Teejay Lanka</div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      SAP Solution
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">SAP SuccessFactors</div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      Mode
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">
                      Offshore implementation
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      Start date
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">August 2020</div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      End date
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">March 2021</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="get-in-touch blue d-flex align-items-center justify-content-center">
          <div className="get-in-touch-content text-center container">
            <p>Get In Touch With Our Experts</p>
            <Link to="/contact">
              <button
                className="btn btn-primary blue-contact-footer"
                type="button"
              >
                Let's Get to Work
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query stratagicHR {
    subHeader: file(relativePath: { eq: "success-stories/subHeader.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default StratagicHR
